import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Projects = (props) => {
  const projects = props.data.allMdx.edges;
  return (
    <Layout bodyClass="page-projects">
      <SEO 
      title="projects"
      description="this is the project desc."
      />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Projects</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {projects.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-6">
              <div className="card service service-teaser">
                <div className="card-content">
                <Link to={edge.node.frontmatter.path}>
                {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="img-fluid mb-2"
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                    )}
                  <h2>
                  {edge.node.frontmatter.title}
                  </h2>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProjectQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "../../mdx/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            image
          }
        }
      }
    }
  }
`;

export default Projects;
